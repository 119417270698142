<template>
    <div class="home">
        <div class="zpanle">
            <div class="baccount-tit" style="margin-top: -20px;">
                <h3 class="g-title">评价管理</h3>
            </div>
            <div class="panner-bd eval-bd">
                <div class="mark-wrap">
                    <div class="mark-cont">
                        <span class="mark">{{userInfo.info.credence }}分</span>
                        <div class="mark-star">
                            <span class="mstar">
                                <el-rate
                                    :value="parseFloat(userInfo.info.credence)"
                                    disabled
                                    disabled-void-color="#e1b83f "
                                    :colors="colors"
                                ></el-rate>
                            </span>
                            <h4 class="wrat-tit">我的信用评分</h4>
                        </div>
                    </div>
                </div>

                <div class="eval-box">
                    <el-table v-if="tableData" :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" width="60"></el-table-column>

                        <el-table-column prop="sn" label="项目编号" width="180"></el-table-column>

                        <el-table-column prop="task_name" label="项目名称">
                            <template slot-scope="props">
                                <p class="taskname">{{ props.row.task_name }}</p>
                            </template>
                        </el-table-column>

                        <el-table-column prop="complate_time" label="项目完成时间" width="170"></el-table-column>

                        <!--极客-->
                        <template v-if="userInfo.info.user_type==1">
                            <el-table-column prop="geek_content" label="我的评价" width="100">
                                <template slot-scope="props">
                                    <a
                                        href="javascript:void(0)"
                                        class="hasjudge"
                                        v-if="props.row.geek_content"
                                    >已评价</a>
                                    <a
                                        href="javascript:void(0)"
                                        class="gojudge"
                                        v-if="!props.row.geek_content "
                                        @click="(e)=>handleClickjudgeMenu(props.row)"
                                    >去评价</a>
                                </template>
                            </el-table-column>

                            <el-table-column prop="employer_content" label="对方评价" width="160">
                                <template slot-scope="props">
                                    <span class="waitejudge" v-if="!props.row.employer_content ">待评价</span>
                                    <span class="dhasjudge" v-if="props.row.employer_content ">已评价</span>
                                </template>
                            </el-table-column>
                        </template>

                        <!--雇主-->
                        <template v-else>
                            <el-table-column prop="employer_content" label="我的评价" width="100">
                                <template slot-scope="props">
                                    <a
                                        href="javascript:void(0)"
                                        class="hasjudge"
                                        v-if="props.row.employer_content"
                                    >已评价</a>
                                    <a
                                        href="javascript:void(0)"
                                        class="gojudge"
                                        v-if="!props.row.employer_content "
                                        @click="(e)=>handleClickjudgeMenu(props.row)"
                                    >去评价</a>
                                </template>
                            </el-table-column>

                            <el-table-column prop="geek_content" label="对方评价" width="160">
                                <template slot-scope="props">
                                    <span class="waitejudge" v-if="!props.row.geek_content ">待评价</span>
                                    <span class="dhasjudge" v-if="props.row.geek_content ">已评价</span>
                                </template>
                            </el-table-column>
                        </template>

                        <el-table-column prop="seedetails" label="查看详情">
                            <template slot-scope="props">
                                <a
                                    href="javascript:void(0)"
                                    class="lokdetail"
                                    @click="(e)=>showAppraise(props.$index,props.row)"
                                >查看</a>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="eval-wraper">
                        <pagination
                            :pageSize="pageSize"
                            :currentPage="1"
                            :pageTotal="total"
                            @current-change="newPage"
                        ></pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 评价详情 -->
        <el-dialog title="评价详情" :visible.sync="handlelodetail" width="800px" class="eva-dialog">
            <div class="eva-detail">
                <div class="eva-tit">
                    <span class="eva-label">项目名称：</span>
                    <p>{{rowDetailData.task_name}}</p>
                </div>
                <ul class="evadetail-list">
                    <li>
                        <h4 class="eva-label" style="width: 200px;">我对{{userInfo.info.user_type==1?rowDetailData.employer_name:rowDetailData.geek_name}}({{userInfo.info.user_type==1?'雇主':'极客'}})的评价：</h4>
                        <div class="eva-cnt">
                            <div class="eva-txt" v-if="userInfo.info.user_type==1">
                                <div v-if="rowDetailData.geek_content">
                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">需求明确：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.demand_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.demand_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">支付速度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.pay_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.pay_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">对接态度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.dock_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.dock_score}}分</span>
                                    </p>

                                    <div class="ev-detail">评价内容：{{rowDetailData.geek_content}}</div>
                                </div>

                                <div class="ev-detail" v-else>
                                    <!-- 您还没对雇主进行评价 -->
                                    <p class="no-comment">
                                        您还没对雇主进行评价
                                        <a
                                            href="javascript:void(0)"
                                            @click="showAppraiseDialog(rowDetailData)"
                                            class="gojudge"
                                        >去评价</a>
                                    </p>
                                </div>
                            </div>

                            <div class="eva-txt" v-else>
                                <div v-if="rowDetailData.employer_content">
                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作态度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.attitude_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.attitude_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作速度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.speed_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.speed_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作质量：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.quality_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.quality_score}}分</span>
                                    </p>

                                    <div class="ev-detail">评价内容：{{rowDetailData.employer_content}}</div>
                                </div>

                                <div class="ev-detail" v-else>
                                    <!-- 您还没对雇主进行评价 -->
                                    <p class="no-comment">
                                        您还没对极客进行评价
                                        <a
                                            href="javascript:void(0)"
                                            @click="showAppraiseDialog(rowDetailData)"
                                            class="gojudge"
                                        >去评价</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <h4 class="eva-label" style="width:200px;">{{userInfo.info.user_type==1?rowDetailData.employer_name:rowDetailData.geek_name}}({{userInfo.info.user_type==1?'雇主':'极客'}})对我评价：</h4>
                        <div class="eva-cnt">
                            <div class="eva-txt" v-if="userInfo.info.user_type==1">
                                <div v-if="rowDetailData.employer_content">
                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作态度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.attitude_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.attitude_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作速度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.speed_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.speed_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">工作质量：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.quality_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.quality_score}}分</span>
                                    </p>
                                    <div class="ev-detail">评价内容：{{rowDetailData.employer_content}}</div>
                                </div>

                                <div class="ev-detail" v-else>
                                    <!-- 暂未评价 -->
                                    <p class="no-comment">雇主暂未评价</p>
                                </div>
                            </div>

                            <div class="eva-txt" v-else>
                                <div v-if="rowDetailData.geek_content">
                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">需求明确：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.demand_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.demand_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">支付速度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.pay_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.pay_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">对接态度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="rowDetailData.dock_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{rowDetailData.dock_score}}分</span>
                                    </p>
                                    <div class="ev-detail">评价内容：{{rowDetailData.geek_content}}</div>
                                </div>

                                <div class="ev-detail" v-else>
                                    <p class="no-comment">极客暂未评价</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

        <!-- 评价 - 雇主 -->
        <el-dialog
            class="blue-top-border goeva-dialog"
            title="评价工程极客"
            :visible.sync="handlegojudge"
            width="640px"
            center
            v-if="userInfo.info.user_type==2"
        >
            <div class="gojudge-wrap">
                <ul class="gojudge-list">
                    <li>
                        <span class="eva-label">项目名称：</span>
                        <p class="jud-tit">{{rowDetailData.task_name}}</p>
                    </li>
                    <li>
                        <span class="eva-label">项目酬金：</span>
                        <p>
                            ￥
                            <i>{{rowDetailData.remuneration}}</i>元
                        </p>
                    </li>
                    <li>
                        <span class="eva-label">确认支付时间：</span>
                        <p>{{rowDetailData.complate_time}}</p>
                    </li>
                </ul>
                <div class="judge-form">
                    <el-form label-width="120px" :rules="rules" ref="ruleForm" :model="ruleForm">
                        <el-form-item label="工作态度:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.attitude_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.attitude_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="工作速度:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.speed_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.speed_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="工作质量:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.demand_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.demand_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="评价内容:" prop="employer_content" name="desc">
                            <el-input
                                type="textarea"
                                v-model="ruleForm.employer_content"
                                placeholder="输入评价内容"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer goeva-ft-wrap">
                <el-button @click="save">评价</el-button>
            </span>
        </el-dialog>

        <!-- 评价 - 极客 -->
        <el-dialog
            class="blue-top-border goeva-dialog"
            title="评价雇主"
            :visible.sync="handlegojudge"
            width="640px"
            center
            v-else
        >
            <div class="gojudge-wrap">
                <ul class="gojudge-list">
                    <li>
                        <span class="eva-label">项目名称：</span>
                        <p class="jud-tit">{{rowDetailData.task_name}}</p>
                    </li>
                    <li>
                        <span class="eva-label">项目酬金：</span>
                        <p>
                            ￥
                            <i>{{rowDetailData.remuneration}}</i>元
                        </p>
                    </li>
                    <li>
                        <span class="eva-label">确认支付时间：</span>
                        <p>{{rowDetailData.complate_time}}</p>
                    </li>
                </ul>
                <div class="judge-form">
                    <el-form label-width="120px" :rules="rules" ref="ruleForm" :model="ruleForm">
                        <el-form-item label="需求明确:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.demand_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.demand_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="支付速度:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.pay_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.pay_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="对接态度:">
                            <p class="jd-rate">
                                <el-rate v-model="ruleForm.dock_score" text-color="#ff9900"></el-rate>
                                <span>{{ruleForm.dock_score}}分</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="评价内容:" prop="geek_content" name="desc">
                            <el-input
                                type="textarea"
                                v-model="ruleForm.geek_content"
                                placeholder="输入评价内容"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer goeva-ft-wrap">
                <el-button @click="save">评价</el-button>
            </span>
        </el-dialog>

        <!-- 评价成功对话框 -->
        <el-dialog
            class="blue-top-border goeva-dialog-success"
            title="评价成功"
            :visible.sync="dialogeval"
            width="640px"
            center
        >
            <div class="gojudge-sucess">
                <i class="jk-iconchenggong1"></i>
                <p>评价成功</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogeval = false">返回</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "appraiseList",
    metaInfo() {
        return {
            title: "评价管理 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        Pagination //分页
    },
    data() {
        return {
            curPage: 1, // 当前分页
            pageSize: 10, // 每页显示条数
            total: 1, // 总页数
            value: 4.7,
            colors: ["#fff", "#fff", "#fff"],
            handlelodetail: false, //评价详情
            handlegojudge: false, //评价对话框
            dialogeval: false, //评价成功提示对话框
            tableData: "",
            rowDetailData: "", //某一条项目的评价信息
            rowtaskId: "",
            tk: "",
            ruleForm: {
                attitude_score: 0,
                speed_score: 0,
                quality_score: 0,
                employer_content: "",
                demand_score: 0,
                pay_score: 0,
                dock_score: 0,
                geek_content: ""
            },
            rules: {
                geek_content: [
                    {
                        required: true,
                        message: "评价内容不能为空",
                        trigger: "blur"
                    }
                ],
                employer_content: [
                    {
                        required: true,
                        message: "评价内容不能为空",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        //查看评价
        showAppraise(idx, rows) {
            this.rowDetailData = rows;
            this.handlelodetail = true;
            this.tk = rows.tk;
        },

        //弹出评价对话框
        showAppraiseDialog() {
            this.handlelodetail = false;
            setTimeout(() => {
                this.handlegojudge = true;
            }, "500");
        },

        //发布评价
        save() {
            var _this = this;
            _this.ruleForm.tk = _this.tk;
            _this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    _this.post(
                        "/task/task/task_appraise",
                        _this.ruleForm,
                        res => {
                            if (res.code == 200) {
                                _this.$message.success("评价成功");
                                _this.handlegojudge = false;
                                _this.loadDataList();
                            } else {
                                if (res.msg) {
                                    _this.$message.error(res.msg);
                                } else {
                                    _this.$message.error("评价失败");
                                }
                            }
                        }
                    );
                }
            });
        },

        handleClickjudgeMenu(rows) {
            this.rowDetailData = rows;
            this.handlegojudge = true;
            this.tk = rows.tk;
        },

        //加载评价列表
        loadDataList() {
            var _this = this;
            _this.post(
                "/task/task/appraise_list",
                {
                    page: _this.curPage,
                    limit: _this.pageSize
                },
                res => {
                    if (res.code == 200) {
                        _this.tableData = res.data.list;
                        _this.total = res.data.count;
                    }
                }
            );
        },

        //分页
        newPage(page) {
            let _this = this;
            _this.currentPage = page;
            _this.loadDataList();
        }
    },
    created() {
        this.loadDataList();
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>

<style lang="less" scoped>
@import "../../../styles/evaluate.less";
/deep/.el-table {
    th {
        .cell {
            font-size:14px;
            color:#666;
            font-weight: normal;
        }
    }
}
/deep/.el-table__empty-text {
    display: inline-block;
    height: 200px;
    line-height: 150px;
}
</style>


